import React from 'react'
import SEO from '~/components/seo'
import { BrowserView, MobileView, MobileOnlyView } from 'react-device-detect'
import Navigation from '../components/Navigation'
import {
  GeneralGrid,
  GeneralGridMobileProduct,
  LockbookWrapperMobile,
  LockbookWrapper,
} from '~/utils/styles'
import Photo1 from '../lookbook/6.jpg'
import Photo2 from '../lookbook/10.jpg'
import Photo3 from '../lookbook/11.jpg'
import Photo4 from '../lookbook/14.jpg'
import Photo5 from '../lookbook/19.jpg'
import Photo6 from '../lookbook/23.jpg'
import Photo7 from '../lookbook/28.jpg'
import Photo8 from '../lookbook/32.jpg'
import Photo9 from '../lookbook/38.jpg'
import Photo10 from '../lookbook/39.jpg'
import Photo11 from '../lookbook/40.jpg'
import Photo12 from '../lookbook/43.jpg'
import Photo13 from '../lookbook/44.jpg'
import Photo14 from '../lookbook/46.jpg'
import Photo15 from '../lookbook/51.jpg'
import Photo16 from '../lookbook/54.jpg'
import Photo17 from '../lookbook/59.jpg'
import Photo18 from '../lookbook/61.jpg'
import Photo19 from '../lookbook/68.jpg'
import Photo20 from '../lookbook/72.jpg'
import Photo21 from '../lookbook/73.jpg'
import Photo22 from '../lookbook/77.jpg'
import Photo23 from '../lookbook/78.jpg'
import Photo24 from '../lookbook/79.jpg'
import Photo25 from '../lookbook/83.jpg'
import Photo26 from '../lookbook/85.jpg'
import Photo27 from '../lookbook/96.jpg'
import Photo28 from '../lookbook/99.jpg'
import Photo29 from '../lookbook/104.jpg'
import Photo30 from '../lookbook/107.jpg'
import Photo31 from '../lookbook/113.jpg'
import Photo32 from '../lookbook/115.jpg'

const Lookbook = () => {
  return (
    <>
      <SEO
        title="BDST | BY BEN TAVERNITI | Bureau de Stil"
        keywords={[
          `bureau de Stil`,
          `ben tavertini`,
          `kim kardashian`,
          'LA Fashion',
          'fashion',
          'joyce bonelli',
          'unravel project',
          'trend brand',
          'online shop',
        ]}
      />
      <BrowserView>
        <GeneralGrid>
          <Navigation />
          <LockbookWrapper>
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo1 - Bureau de Stil'}
              src={Photo1}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo2 - Bureau de Stil'}
              src={Photo2}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo3 - Bureau de Stil'}
              src={Photo3}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo4 - Bureau de Stil'}
              src={Photo4}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo5 - Bureau de Stil'}
              src={Photo5}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo6 - Bureau de Stil'}
              src={Photo6}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo7 - Bureau de Stil'}
              src={Photo7}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo8 - Bureau de Stil'}
              src={Photo8}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo9 - Bureau de Stil'}
              src={Photo9}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo10 - Bureau de Stil'}
              src={Photo10}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo11 - Bureau de Stil'}
              src={Photo11}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo12 - Bureau de Stil'}
              src={Photo12}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo13 - Bureau de Stil'}
              src={Photo13}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo14 - Bureau de Stil'}
              src={Photo14}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo15 - Bureau de Stil'}
              src={Photo15}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo16 - Bureau de Stil'}
              src={Photo16}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo17 - Bureau de Stil'}
              src={Photo17}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo18 - Bureau de Stil'}
              src={Photo18}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo19 - Bureau de Stil'}
              src={Photo19}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo20 - Bureau de Stil'}
              src={Photo20}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo21 - Bureau de Stil'}
              src={Photo21}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo22 - Bureau de Stil'}
              src={Photo22}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo23 - Bureau de Stil'}
              src={Photo23}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo24 - Bureau de Stil'}
              src={Photo24}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo25 - Bureau de Stil'}
              src={Photo25}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo26 - Bureau de Stil'}
              src={Photo26}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo27 - Bureau de Stil'}
              src={Photo27}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo28 - Bureau de Stil'}
              src={Photo28}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo29 - Bureau de Stil'}
              src={Photo29}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo30 - Bureau de Stil'}
              src={Photo30}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo31 - Bureau de Stil'}
              src={Photo31}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo32 - Bureau de Stil'}
              src={Photo32}
            />
          </LockbookWrapper>
        </GeneralGrid>
      </BrowserView>
      <MobileOnlyView>
        <Navigation />
        <GeneralGridMobileProduct>
          <LockbookWrapperMobile>
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo1 - Bureau de Stil'}
              src={Photo1}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo2 - Bureau de Stil'}
              src={Photo2}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo3 - Bureau de Stil'}
              src={Photo3}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo4 - Bureau de Stil'}
              src={Photo4}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo5 - Bureau de Stil'}
              src={Photo5}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo6 - Bureau de Stil'}
              src={Photo6}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo7 - Bureau de Stil'}
              src={Photo7}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo8 - Bureau de Stil'}
              src={Photo8}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo9 - Bureau de Stil'}
              src={Photo9}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo10 - Bureau de Stil'}
              src={Photo10}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo11 - Bureau de Stil'}
              src={Photo11}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo12 - Bureau de Stil'}
              src={Photo12}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo13 - Bureau de Stil'}
              src={Photo13}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo14 - Bureau de Stil'}
              src={Photo14}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo15 - Bureau de Stil'}
              src={Photo15}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo16 - Bureau de Stil'}
              src={Photo16}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo17 - Bureau de Stil'}
              src={Photo17}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo18 - Bureau de Stil'}
              src={Photo18}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo19 - Bureau de Stil'}
              src={Photo19}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo20 - Bureau de Stil'}
              src={Photo20}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo21 - Bureau de Stil'}
              src={Photo21}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo22 - Bureau de Stil'}
              src={Photo22}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo23 - Bureau de Stil'}
              src={Photo23}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo24 - Bureau de Stil'}
              src={Photo24}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo25 - Bureau de Stil'}
              src={Photo25}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo26 - Bureau de Stil'}
              src={Photo26}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo27 - Bureau de Stil'}
              src={Photo27}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo28 - Bureau de Stil'}
              src={Photo28}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo29 - Bureau de Stil'}
              src={Photo29}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo30 - Bureau de Stil'}
              src={Photo30}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo31 - Bureau de Stil'}
              src={Photo31}
            />
            <img
              style={{ width: '100%' }}
              alt={'Lookbook Photo32 - Bureau de Stil'}
              src={Photo32}
            />
          </LockbookWrapperMobile>
        </GeneralGridMobileProduct>
      </MobileOnlyView>
    </>
  )
}

export default Lookbook
